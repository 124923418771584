@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
html {
    overflow-x: hidden !important;
}

body {
    font-family: "Inter", sans-serif;
}

input[type="button"] {
    outline: none;
}

*:focus {
    outline: none !important;
    @apply !ring-0;
}
input[type="button"] {
    outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

@layer components {
    input[type="range"] {
        @apply bg-transparent appearance-none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        @apply bg-gray-100 rounded-full;
    }

    input[type="range"]::-moz-range-track {
        @apply bg-gray-100 rounded-full;
    }

    input[type="range"]::-ms-track {
        @apply bg-gray-100 rounded-full;
    }

    .blinking-cursor:after {
        content: "|";
        @apply text-gradient;
        animation: blink 0.7s step-start infinite;
    }

    @keyframes blink {
        50% {
            opacity: 0;
        }
    }
}

h2 {
    @apply mb-5 text-4xl font-semibold leading-tight lg:text-5xl;
}

h3 {
    @apply text-lg font-semibold leading-tight;
}

.wrapper {
    @apply flex flex-col items-center justify-center px-5 py-20 lg:py-24 lg:px-10;
}

.container {
    @apply max-w-screen-xl mx-auto;
}

.center {
    @apply flex self-center justify-center;
}

.intercom-lightweight-app-launcher {
    @apply bg-gradient;
}

.gradient {
    background: rgb(92, 107, 238);
    background: linear-gradient(
        331deg,
        rgba(92, 107, 238, 1) 0%,
        rgba(159, 80, 184, 1) 32%,
        rgba(193, 70, 172, 1) 49%,
        rgba(221, 62, 161, 1) 63%,
        rgba(251, 107, 92, 1) 93%
    );
}

.gradient-transparent {
    background: rgb(92, 107, 238);
    background: linear-gradient(
        331deg,
        rgba(92, 107, 238, 0) 0%,
        rgba(159, 80, 184, 0) 32%,
        rgba(193, 70, 172, 0) 49%,
        rgba(221, 62, 161, 0) 63%,
        rgba(92, 107, 238, 1) 93%,
        rgba(159, 80, 184, 1) 100%
    );
}

.text-gradient {
    background: rgb(92, 107, 238);
    background: linear-gradient(
        331deg,
        rgba(92, 107, 238, 1) 0%,
        rgba(159, 80, 184, 1) 32%,
        rgba(193, 70, 172, 1) 49%,
        rgba(221, 62, 161, 1) 63%,
        rgba(251, 107, 92, 1) 93%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

text-black {
    background: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: black;
}
.webkit-text-black {
    -webkit-text-fill-color: black;
}

.bg-gradient {
    background: rgb(92, 107, 238);
    background: linear-gradient(
        331deg,
        rgba(92, 107, 238, 1) 0%,
        rgba(159, 80, 184, 1) 32%,
        rgba(193, 70, 172, 1) 49%,
        rgba(221, 62, 161, 1) 63%,
        rgba(251, 107, 92, 1) 93%
    );
}
.bd-gradient {
    background: rgba(92, 107, 238, 0.07);
    background: linear-gradient(
        331deg,
        rgba(92, 107, 238, 0.07) 0%,
        rgba(159, 80, 184, 0.07) 32%,
        rgba(193, 70, 172, 0.07) 49%,
        rgba(221, 62, 161, 0.07) 63%,
        rgba(251, 107, 92, 0.07) 93%
    );
}

.button-gradient {
    @apply bg-gradient inline-flex items-center gap-1.5 rounded-md px-4 py-3 font-medium leading-none tracking-wide text-button-text shadow-md transition-all hover:shadow-lg hover:-hue-rotate-15 active:shadow lg:text-sm;
}

.text-shadow {
    text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.text-shadow-lg {
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 0.99);
}

.gradient-border {
    --borderWidth: 3px;
    position: relative;
    border-radius: var(--borderWidth);
}

.gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(
        331deg,
        rgba(92, 107, 238, 1) 0%,
        rgba(221, 62, 161, 1) 40%,
        rgba(251, 107, 92, 1) 80%
    );
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 5s ease-in-out alternate-reverse infinite;
    background-size: 300% 300%;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"] {
        width: 100%;
        -webkit-appearance: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        height: 20px;
    }

    input[type="range"]::-webkit-slider-thumb {
        position: relative;
        bottom: 2.5px;
        width: 25px;
        -webkit-appearance: none;
        height: 25px;
        cursor: ew-resize;
        @apply bg-gradient;
        border-radius: 100%;
    }
    input[type="range"]::-webkit-slider-thumb::before {
        background-color: red;
        height: 15px;
        width: 15px;
    }
}

.chart-container {
    width: 1000px;
    height: 600px;
}

.z-back {
    z-index: -1;
}

@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

.journey {
    font-family: "Pacifico", cursive;
}

.slick-arrow {
    @apply z-[9999] !h-20 !w-20;
}

.slick-prev:before,
.slick-next:before {
    @apply flex !items-center !justify-center bg-white/20 bg-clip-text !text-4xl leading-none !text-transparent;
}
